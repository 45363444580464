$(function () {

    $('[data-toggle="tooltip"]').tooltip();

    // if device mobile

    function countIndents(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            var reservForm = $('.reserv-sidebar .base-form');
            var reservFormHeight = reservForm.outerHeight();
            var leftRowHeight = $('.port-content > .row > .col-md-6:first-of-type').outerHeight();

            if(reservForm){
                $('.port-content').addClass('port-content-mobile-fix').css('padding-top',(reservFormHeight + 40) + 'px');
                reservForm.css('top','calc(-' + (reservFormHeight + 20) + 'px - ' + leftRowHeight +'px)');
            }

        }
    }
    countIndents();

    $( window ).resize(function() {
        countIndents();
    });

    setTimeout(countIndents, 2000);

    // footer pages ZAPIL

    function pullForms(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

            if($('.base-template #main-content .footer-page').length > 0){
                var formForCut = $('.footer-page > .row form').closest('.fp-block')[0];
                formForCut.remove();
                $('.footer-page > .row > div:first-child').prepend(formForCut.outerHTML);

            }
        }
    }

    setTimeout(pullForms, 1000);

    // custom pages ZAPIL

    function pullForms2(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

            if($('.custom-mob-form').length > 0){

                for(var i = 0; i < $('form.base-form').length; i++){

                    var formForCut = $('form.base-form')[i];
                    formForCut.remove();
                    $('.base-template > .container #main-content').prepend(formForCut.outerHTML);

                }

            }
        }
    }

    setTimeout(pullForms2, 1000);


    let backToTopBtn = $("#backToTopBtn");

    window.onscroll = function() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            backToTopBtn.removeClass('d-none');
        } else {
            backToTopBtn.addClass('d-none');
        }
    };

    backToTopBtn.on("click", function (){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });

});
